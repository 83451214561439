import React from 'react';
import Layout from '../../components/layout';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import './picks.scss';

const Page = () => (
  <Layout>
    <Helmet>
      <body page-name="page-picks" />
    </Helmet>

    <main>
      <h1>
        <Link to="/picks">picks</Link> / newsletters
      </h1>

      <p>Interesting things currently arriving in my inbox</p>

      <dl>
        {newsletters.map(([title, url, desc]) => (
          <React.Fragment key={title}>
            <h3>{title}</h3>
            <p>
              <a href={url} target="_blank">
                <code>{url}</code>
              </a>
            </p>
            {desc ? <p>{desc}</p> : null}
          </React.Fragment>
        ))}
      </dl>
    </main>
  </Layout>
);

//prettier-ignore
const newsletters = [
  [
    'Changelog Weekly',
    'https://changelog.com/weekly',
    'Editorialized take on the week in open source and software development'
  ],
  [
    'Mozilla Developer Newsletter',
    'https://www.mozilla.org/newsletter/developer'
  ],
  [
    'Codrops Collective',
    'https://tympanus.net/codrops/collective',
    'The latest news and resources from the web design & web development community'
  ],
  [
    'Pony Foo Weekly',
    'https://ponyfoo.com/weekly',
    'Discussing interesting and trending topics around the web platform'
  ],
  [
    'CSS Layout News',
    'http://csslayout.news'
  ],
  [
    'ES.next News',
    'http://esnextnews.com',
    '5 ECMAScript.next links every week'
  ],
  [
    'npm Weekly',
    'https://www.npmjs.com/npm-weekly',
  ],
  [
    'Typewolf Tuesday',
    'https://www.typewolf.com/newsletter',
    'A short and sweet roundup of the latest type news and typeface releases'
  ],
  [
    'TypeScript Weekly',
    'https://typescript-weekly.com',
  ],
  [
    'Security Newsletter',
    'https://securitynewsletter.co',
    'Last week’s news on infosecurity, digested to an easy format',
  ],
  [
    'GoodUI Newsletter',
    'https://goodui.org/'
  ]
];

export default Page;
